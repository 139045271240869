import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { Avatar } from 'components/Avatar'
import { Badge } from 'components/Badge'
import { Flex } from 'components/Flex'
import { Redact } from 'components/Redact'
import { Table } from 'components/Table'
import { useSession } from 'hooks/use-session'
import { PhaseRecipient } from 'types'
import { formatPercent } from 'utils/percent'

type Props = {
  data: (PhaseRecipient & { isDefault?: boolean })[]
}

function TableSpreadAllocation({ data = [] }: Props) {
  const { ability } = useSession()
  const columns: ColumnDef<PhaseRecipient & { isDefault?: boolean }>[] =
    useMemo(
      () => [
        {
          header: 'Name',
          accessorKey: 'name',
          cell: ({ getValue, row }) => (
            <Flex gap={8} alignItems="center">
              <Avatar
                name={
                  ability.cannot('read', 'investors')
                    ? ''
                    : (getValue() as string)
                }
                id={row.original?.id}
              />
              <div>
                <Redact
                  value={getValue() as string}
                  hide={ability.cannot('read', 'investors')}
                />
              </div>
              {row.original?.isDefault && (
                <Badge color="gray">Surplus and Deficit</Badge>
              )}
            </Flex>
          ),
        },
        {
          header: data.length ? 'Rate' : '',
          accessorKey: 'percentage',
          size: 100,
          cell: ({ row }) =>
            formatPercent(row.original?.percentage, {
              maxDecimals: 3,
              showZero: true,
            }),
          meta: {
            align: 'right',
          },
        },
      ],
      [data]
    )

  return <Table columns={columns} data={data} className="w-full" />
}

export { TableSpreadAllocation }
