import { useNavigate, useParams } from 'react-router-dom'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { pathTo } from 'admin/path-to'
import { Flex } from 'components/Flex'
import { Tabs } from 'components/Tabs'
import { useSession } from 'hooks/use-session'
import { PersonPanel } from './PersonPanel'
import { ProductPanel } from './ProductPanel'

function CustomFields() {
  const { ability } = useSession()
  const navigate = useNavigate()
  const { tab } = useParams() as { tab: 'product' | 'borrower' }

  const showProducts = ability.can('read', 'products')
  const showBorrowers = ability.can('read', 'borrowers')
  const showInvestors = ability.can('read', 'investors')
  const showVendors = ability.can('read', 'vendors')

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop title="Custom Data Fields" />
        <Tabs
          tab={tab}
          defaultActiveId="product"
          onTabSelect={(tab) => {
            navigate(pathTo('settingsCustomFields', tab), { replace: true })
          }}
        >
          {showProducts ? (
            <Tabs.Pane tab="Product" id="product" className="pt-5">
              <ProductPanel />
            </Tabs.Pane>
          ) : null}
          {showBorrowers ? (
            <Tabs.Pane tab="Borrower" id="borrower" className="pt-5">
              <PersonPanel personType="borrower" />
            </Tabs.Pane>
          ) : null}
          {showInvestors ? (
            <Tabs.Pane tab="Investor" id="investor" className="pt-5">
              <PersonPanel personType="investor" />
            </Tabs.Pane>
          ) : null}
          {showVendors ? (
            <Tabs.Pane tab="Vendor" id="vendor" className="pt-5">
              <PersonPanel personType="vendor" />
            </Tabs.Pane>
          ) : null}
        </Tabs>
      </Flex>
    </MainContent>
  )
}

export { CustomFields }
