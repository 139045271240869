import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  useUploadSizer,
  useDownloadSizer,
  useDeleteSizer,
} from 'admin/hooks/use-sizer'
import { pathTo } from 'admin/path-to'
import { Product } from 'admin/services/api/products'
import { getSizerRuns } from 'admin/services/api/sizer'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { TextLink } from 'components/TextLink'
import { useSession } from 'hooks/use-session'
import { friendlyDateTime } from 'utils/date'
import { openBrowseFile } from 'utils/file'
import styles from './styles.module.scss'

interface Props {
  product?: Product
  onInteract?: () => Promise<Product>
}

export const Sizer = ({ product, onInteract }: Props) => {
  const navigate = useNavigate()
  const { ability } = useSession()
  const { mutate: downloadSizer } = useDownloadSizer()
  const { mutate: deleteSizer } = useDeleteSizer()
  const { mutate: upload } = useUploadSizer()
  const [runs, setRuns] = useState<undefined | any[]>(undefined)

  const getProductId = useCallback(async (): Promise<string> => {
    let id = product?.id
    if (!product && onInteract) {
      const newProduct = await onInteract()
      id = newProduct.id
    }
    return id as string
  }, [product, onInteract])

  const handleUpload = useCallback(async () => {
    const id = await getProductId()
    openBrowseFile({
      onChoose: (files) => upload({ productId: id, file: files[0] }),
      accept: '.xlsx',
    })
  }, [getProductId])
  const handleDownload = useCallback(async () => {
    const id = await getProductId()
    downloadSizer(id)
  }, [getProductId])
  const handleDelete = useCallback(async () => {
    if (product?.id) {
      deleteSizer(product.id)
    }
  }, [product])

  const isDownloadable = ability.can('read', 'quote-sizer')

  useEffect(() => {
    if (product?.id) {
      getSizerRuns(product.id).then(({ runs: results }) => {
        setRuns(results.filter((run) => run.quote).slice(0, 10))
      })
    }
  }, [product])

  return (
    <Flex stack>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        className={styles.template}
      >
        <a className="link" onClick={handleDownload}>
          Sizer
        </a>
        <Flex gap={4}>
          <div className={styles.icon} onClick={handleUpload}>
            <Icon name={IconName.upload} />
          </div>
          <div className={styles.icon} onClick={handleDownload}>
            <Icon name={IconName.download} />
          </div>
          <div className={styles.icon} onClick={handleDelete}>
            <Icon name={IconName.documentRefresh} />
          </div>
        </Flex>
      </Flex>
      {runs && !!runs.length && isDownloadable && (
        <Grid gap={8}>
          <Grid.Item className="font-bold">Run History</Grid.Item>
          {runs.map((run) => (
            <Grid.Item key={run.createdAt}>
              {friendlyDateTime(run.createdAt)} on{' '}
              <TextLink
                onClick={() => {
                  navigate(pathTo('quote', 'all', run.quote.id))
                }}
              >
                {run.quote.name}
              </TextLink>{' '}
              <Button
                size="small"
                variant="tertiary"
                onClick={() => {
                  window.location.href = run.url
                }}
              >
                Download
              </Button>
            </Grid.Item>
          ))}
        </Grid>
      )}
    </Flex>
  )
}
