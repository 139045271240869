import { Button } from '../../Button'
import { Text } from '../../Text'
import { Modal } from '../index'
import styles from './styles.module.scss'

interface ModalAddLoanProps {
  resource: string
  name?: string
  loading?: boolean
  title?: string
  action?: string
  onDelete: () => void
  onCancel: () => void
}

function ModalDelete({
  resource,
  name,
  loading,
  title,
  action = 'Delete',
  onDelete,
  onCancel,
}: ModalAddLoanProps) {
  return (
    <Modal title={title ?? `Delete ${resource}`} onClose={onCancel}>
      <div className={styles.content}>
        <Text>
          Are you sure you want to {action.toLowerCase()} {name || resource}?
        </Text>
        <div className={styles.buttons}>
          <Button variant="tertiary" onClick={onCancel}>
            Cancel
          </Button>
          <Button loading={loading} color="negative" onClick={onDelete}>
            {action ?? 'Delete'}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalDelete
