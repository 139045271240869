interface Props {
  value: any
  hide?: boolean
}

const Redact = ({ value, hide = true }: Props) => {
  const numDots = value?.toString?.().length ?? 9

  if (hide) {
    return <span>{''.padStart(numDots, '•')}</span>
  }
  return value
}

export { Redact }
