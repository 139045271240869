import { Flex } from 'components/Flex'
import { Text } from 'components/Text'

interface Props {
  description: string
}

export const Description = ({ description }: Props) => {
  return (
    <Flex className="mt-4" justifyContent="center">
      <Text className="max-w-115 text-center leading-5 text-grey-700">
        {description}
      </Text>
    </Flex>
  )
}
