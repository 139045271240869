import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ThreadForm } from 'admin/components/ThreadForm'
import { MainContent } from 'admin/components/layout/MainContent'
import { useAddThread } from 'admin/hooks/use-threads'
import { LinkedContacts } from 'admin/pages/Thread/PanelsForLinking/LinkedContacts'
import { LinkedLoans } from 'admin/pages/Thread/PanelsForLinking/LinkedLoans'
import { ThreadProvider } from 'admin/pages/Thread/ThreadProvider'
import { pathTo } from 'admin/path-to'
import { Flex } from 'components/Flex'
import { PageLoader } from 'components/LoaderOverlay'
import { IThread } from 'types'
import { Header } from './Header'

const ThreadNew = () => {
  const navigate = useNavigate()
  const { loanId } = useParams() as { loanId?: string }
  const { mutate: addThread } = useAddThread()
  const [thread, setThread] = useState<IThread>()

  const handleClose = useCallback(() => {
    if (loanId) {
      const isServicing = window.location.pathname.includes('/servicing')
      navigate(
        pathTo(isServicing ? 'servicingLoanTab' : 'loanTab', loanId, 'mail')
      )
    } else {
      navigate(pathTo('threads'))
    }
  }, [navigate, loanId])

  useEffect(() => {
    addThread({ loanId }, { onSuccess: (newThread) => setThread(newThread) })
  }, [loanId, addThread])

  return (
    <MainContent>
      {thread ? (
        <ThreadProvider thread={thread}>
          <Flex stack gap={32}>
            <Header onClose={handleClose} />
            <Flex gap={40}>
              <div className="w-full overflow-hidden">
                <ThreadForm
                  thread={thread}
                  mail={thread.mails[0]}
                  onDelete={handleClose}
                />
              </div>
              <Flex stack gap={32} className="max-w-xs w-full">
                <LinkedLoans />
                <LinkedContacts />
              </Flex>
            </Flex>
          </Flex>
        </ThreadProvider>
      ) : (
        <PageLoader />
      )}
    </MainContent>
  )
}

export { ThreadNew }
