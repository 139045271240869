import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { TopMenu } from 'admin/components/TopMenu'
import { useThreadContext } from 'admin/pages/Thread/ThreadContext'
import { pathTo } from 'admin/path-to'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Tooltip } from 'components/Tooltip'
import { HeaderActions } from './HeaderActions'

interface Props {
  onClose: () => void
}

const Header = ({ onClose }: Props) => {
  const navigate = useNavigate()
  const { thread, deleteThread, updateThread, isDeleting } = useThreadContext()

  const handleArchive = useCallback(() => {
    navigate(pathTo('threads'))
    updateThread({ isArchived: !thread.isArchived })
  }, [navigate, updateThread, thread.isArchived])

  const handleDelete = useCallback(() => {
    navigate(pathTo('threads'))
    deleteThread()
  }, [navigate, deleteThread])

  const handleToggleRead = useCallback(() => {
    navigate(pathTo('threads'))
    updateThread({ isRead: !thread.isRead })
  }, [updateThread, thread.isRead])

  useEffect(() => {
    if (!thread.isRead) {
      updateThread({ isRead: true })
    }
  }, [thread.isRead, updateThread])

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      className="-mx-2 md:-mx-8 md:-mt-4 lg:-mx-14 md:border-0 md:border-b md:border-solid md:border-grey-100 md:h-12 pl-2"
    >
      <Button variant="ghost" onClick={onClose} className="w-8">
        <Icon name={IconName.close} size="md" className="text-grey-600" />
      </Button>
      <Flex gap={0} alignItems="center">
        <Flex
          gap={10}
          alignItems="center"
          className="h-8 mr-3 px-4 border-0 border-r border-solid border-grey-100"
        >
          <Tooltip content={thread.isArchived ? 'Unarchive' : 'Archive'}>
            <Button variant="ghost" onClick={handleArchive} className="w-8">
              <Icon
                name={IconName.archive}
                size="md"
                className="text-grey-600"
              />
            </Button>
          </Tooltip>
          <Tooltip content="Delete">
            <Button
              variant="ghost"
              onClick={handleDelete}
              className="w-8"
              loading={isDeleting}
            >
              <Icon
                name={IconName.delete}
                size="md"
                className="text-grey-600"
              />
            </Button>
          </Tooltip>
          <HeaderActions
            isRead={thread.isRead}
            onToggleRead={handleToggleRead}
          />
        </Flex>
        <TopMenu />
      </Flex>
    </Flex>
  )
}

export { Header }
