type Props = Readonly<{
  accept?: string
  onChange: (files: FileList | null) => void
}>

function InputFile({ accept, onChange }: Props): JSX.Element {
  return (
    <input
      type="file"
      accept={accept}
      className="p-2 border border-solid border-grey-200 rounded-sm text-grey-600"
      onChange={(e) => onChange(e.target.files)}
    />
  )
}

export { InputFile }
