import { Formik } from 'formik'
import { compact, pick } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { InputField } from 'admin/components/table/TableProductFields/InputField'
import { useApplicationFields } from 'admin/hooks/use-application'
import styles from 'admin/pages/Product/ModalField/styles.module.scss'
import { PageKey } from 'admin/pages/Product/View'
import { fields } from 'admin/pages/Settings/Application/fields'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Form, Field, Textarea, Select, Toggle, Option } from 'components/Form'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { Modal } from 'components/Modal'
import { TextLink } from 'components/TextLink'
import { FieldType } from 'types'
import { Field as IField } from 'types/field'
import { getVariablesForFormulas } from 'utils/fields'
import { createScheme, required } from 'utils/schemas'
import { useProductContext } from '../ProductContext'

const FieldSchema = createScheme({
  name: required,
  type: required,
})

const SelectFieldSchema = createScheme({
  field: required,
})

type Step = 'select-field' | 'form'

type FormValues = {
  name: string
  type: FieldType[]
  description: string
  options: { label: string; value: string }[]
  checklist: string
  enabled: boolean
  value?: string
  formula?: string
}

type InnerFormValues = Omit<FormValues, 'type' | 'options'> & {
  type: FieldType
  options: string
}

interface ModalFieldProps {
  existingFields?: IField[]
  field: IField | null
  onSubmit: (values: FormValues) => void
  onCancel: () => void
  page: PageKey
  section: string
}

function ModalField({
  existingFields = [],
  field,
  onSubmit,
  onCancel,
  page,
  section,
}: ModalFieldProps) {
  const { loan, productId } = useProductContext()

  let options = [
    { label: 'Currency', value: 'currency' },
    { label: 'Date', value: 'date' },
    { label: 'Decimal', value: 'decimal' },
    { label: 'Dropdown', value: 'option' },
    { label: 'Number', value: 'number' },
    { label: 'Percentage', value: 'percentage' },
    { label: 'Text', value: 'text' },
    { label: 'Email', value: 'email' },
    { label: 'Phone', value: 'phone' },
    { label: 'Yes/No', value: 'yes-no' },
  ]
  let titleType: 'Field' | 'Document' | 'Charge' | 'Class' = 'Field'
  let typeOverride: FieldType[] | undefined
  switch (field?.page || page) {
    case 'documents':
    case 'Documents':
      options = [{ label: 'Document', value: 'document' }]
      titleType = 'Document'
      break
    case 'charges':
    case 'Charges':
      options = [{ label: 'Currency', value: 'currency' }]
      titleType = 'Charge'
      break
  }
  switch (field?.section || section) {
    case 'Tranches':
    case 'tranches':
      options = [
        {
          label: 'Currency',
          value: 'currency',
        },
      ]
      typeOverride = ['currency', 'percentage'] as FieldType[]
      titleType = 'Class'
      break
  }

  const [searchString, setSearchString] = useState('')

  const initialValue: InnerFormValues = {
    name: field?.name || searchString,
    type:
      field?.type[0] ||
      (options.length === 1 && (options[0].value as FieldType)) ||
      'text',
    description: field?.description || '',
    checklist: field?.checklist || '',
    options: field?.options
      ? field?.options.map(({ value }) => value).join('\r\n')
      : '',
    value: (field?.property.value[0] as string) || '',
    formula: (field?.property.formula as string) || '',
    enabled: field ? field.property.enabled !== 'off' : true,
  }

  const [step, setStep] = useState<Step>(
    field || titleType === 'Class' ? 'form' : 'select-field'
  )
  const [type, setType] = useState<string>(initialValue.type)
  const { data: mapToFields = [], isLoading } = useApplicationFields()
  const existingFieldsIds = useMemo(
    () => existingFields?.map(({ id }) => id),
    [existingFields]
  )
  const mapToFieldsOptions = useMemo(
    () =>
      mapToFields
        .filter(({ id, type }) => {
          if (existingFieldsIds?.includes(id)) {
            return false
          }
          if (
            (titleType === 'Document' && !type.includes('document')) ||
            (titleType !== 'Document' && type.includes('document'))
          ) {
            return false
          }
          if (titleType === 'Charge' && !type.includes('currency')) {
            return false
          }
          return true
        })
        .map(({ id, section, name, type }) => ({
          value: id,
          label: section ? `${section} - ${name}` : name,
          icon: (
            <img
              src={fields.find((field) => type[0] === field.type)?.icon}
              className="h-4 w-4"
            />
          ),
        })),
    [existingFieldsIds, mapToFields]
  )
  const handleSubmit = useCallback(
    (values) => {
      let options: Option[] | undefined = undefined
      if (values.type === 'option') {
        options = values.options
          ? (compact(values.options.split(/\r?\n/)).map((value) => ({
              label: value,
              value: value,
            })) as Option[])
          : []
      }

      onSubmit({
        ...values,
        options,
        type: typeOverride || [values.type],
      })
    },
    [onSubmit]
  )
  const handleSelectSubmit = useCallback(
    ({ field: fieldId }) => {
      const field = mapToFields.find(({ id }) => id === fieldId)
      onSubmit({
        ...(pick(
          field,
          'id',
          'name',
          'type',
          'description',
          'checklist',
          'options'
        ) as FormValues),
        enabled: true,
      })
    },
    [onSubmit, mapToFields]
  )

  return (
    <Modal
      title={field ? `Edit ${titleType}` : `Add ${titleType}`}
      loading={isLoading}
      onClose={onCancel}
      className={styles.modal}
    >
      {step === 'select-field' && (
        <Formik
          initialValues={{ field: '' }}
          validationSchema={SelectFieldSchema}
          onSubmit={handleSelectSubmit}
        >
          <Form>
            <Select
              name="field"
              options={mapToFieldsOptions}
              label="Field"
              placeholder="Find an existing field or create new"
              portal
              fieldClassName="mb-2"
              onChange={(option) => {
                setType(option.value as FieldType as string)
              }}
              noOptionsMessage={({ inputValue }) => (
                <a
                  className="link"
                  onClick={() => {
                    setSearchString(inputValue)
                    setStep('form')
                  }}
                >
                  <Icon name={IconName.plus} size="sm" /> Create a new field
                </a>
              )}
            />
            <TextLink onClick={() => setStep('form')}>
              <Icon name={IconName.plus} size="sm" />
              Create a new field
            </TextLink>
            <div className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </div>
          </Form>
        </Formik>
      )}
      {step === 'form' && (
        <Formik
          initialValues={initialValue}
          validationSchema={FieldSchema}
          onSubmit={handleSubmit}
        >
          {(form) => (
            <Form>
              <Grid className={styles.form} columnGap={16}>
                <Grid.Item xs={12}>
                  <Field
                    type="text"
                    name="name"
                    label="Name"
                    placeholder={`${titleType} name`}
                  />
                </Grid.Item>
                <Grid.Item xs={12}>
                  <Select
                    name="type"
                    options={options}
                    label="Type"
                    portal
                    onChange={(option) => {
                      setType(option.value as FieldType as string)
                    }}
                  />
                </Grid.Item>
                {type === 'option' && (
                  <Grid.Item xs={12}>
                    <Textarea
                      name="options"
                      label="Options"
                      placeholder="A list of options for people to choose from. Enter each option on a new line."
                    />
                  </Grid.Item>
                )}
                <Grid.Item xs={12}>
                  <Textarea
                    name="description"
                    label="Description"
                    placeholder="Description"
                  />
                </Grid.Item>
                {type === 'document' && (
                  <Grid.Item xs={12}>
                    <Textarea
                      name="checklist"
                      label="Checklist"
                      placeholder="Checklist"
                    />
                  </Grid.Item>
                )}
                {!field?.viewOnly &&
                  [
                    'percentage',
                    'currency',
                    'number',
                    'decimal',
                    'text',
                  ].includes(form.values.type) && (
                    <Grid.Item xs={12} className="mb-6">
                      <Flex stack gap={8}>
                        <div className="text-lg">Default Value</div>
                        <InputField
                          loanId={loan?.id}
                          productId={productId}
                          variables={getVariablesForFormulas(
                            existingFields
                          ).filter((name) => name !== field?.name)}
                          field={
                            {
                              id:
                                !field?.id || field.id?.startsWith('new-field-')
                                  ? 'formula'
                                  : field.id,
                              type: [form.values.type],
                              property: {
                                value: [form.values.value],
                                formula: form.values.formula,
                              },
                              options: form.values.options.split('/r/n'),
                            } as unknown as IField
                          }
                          onChange={(data: {
                            value?: string
                            formula?: string
                          }) => {
                            form.setFieldValue('value', data.value)
                            form.setFieldValue('formula', data.formula)
                          }}
                        />
                      </Flex>
                    </Grid.Item>
                  )}
                <Grid.Item xs={12} className={styles.actions}>
                  <Toggle
                    label="Active"
                    name="enabled"
                    fieldClassName={styles.enabled}
                  />
                  <div className={styles.buttons}>
                    <Button variant="tertiary" onClick={onCancel}>
                      Cancel
                    </Button>
                    <Button type="submit">Save</Button>
                  </div>
                </Grid.Item>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  )
}

export type { FormValues }
export default ModalField
