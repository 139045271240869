import clsx from 'clsx'
import { capitalize } from 'lodash'
import { useCallback, useState } from 'react'
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { ThreadsTab } from 'admin/pages/Threads/ThreadsTab'
import { pathTo } from 'admin/path-to'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Search } from 'components/Search'
import { Tabs } from 'components/Tabs'
import { IThreadCheckboxes, IThreadListItem, IThreadTab } from 'types'
import { ThreadsMainCheckbox } from './ThreadsMainCheckbox'

const tabs: { tab: IThreadTab; icon: IconName; hidden?: boolean }[] = [
  {
    tab: 'inbox',
    icon: IconName.inbox,
  },
  {
    tab: 'draft',
    icon: IconName.draftThread,
  },
  {
    tab: 'sent',
    icon: IconName.sendThread,
  },
  {
    tab: 'archived',
    icon: IconName.archive,
    hidden: true,
  },
  {
    tab: 'trash',
    icon: IconName.delete,
    hidden: true,
  },
]

const Threads = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [search, setSearch] = useState<string>()
  const selectedTab = (searchParams.get('tab') as IThreadTab) || 'inbox'
  const [checkboxes, setCheckboxes] = useState<IThreadCheckboxes>({
    mode: null,
    includeIds: [],
    excludeIds: [],
  })

  const handleRowClick = useCallback(
    (thread?: IThreadListItem) => {
      navigate(pathTo('thread', thread!.id))
    },
    [navigate]
  )

  const handleComposeClick = useCallback(() => {
    navigate(pathTo('threadNew'))
  }, [navigate])

  const handleTabSelect = useCallback(
    (tab) =>
      navigate(
        {
          pathname: pathTo('threads'),
          search: createSearchParams({ tab }).toString(),
        },
        { replace: true }
      ),
    [navigate]
  )

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop title="Inbox" />
        <Tabs
          beforeTabs={
            <ThreadsMainCheckbox
              checkboxes={checkboxes}
              onChange={() =>
                setCheckboxes(({ mode }) => ({
                  mode: mode ? null : 'all',
                  includeIds: [],
                  excludeIds: [],
                }))
              }
              onSelect={(mode) =>
                setCheckboxes({
                  mode,
                  includeIds: [],
                  excludeIds: [],
                })
              }
            />
          }
          afterTabs={
            <Flex justifyContent="flex-end" className="w-full">
              <Flex
                justifyContent="flex-end"
                alignItems="center"
                className="bg-white-100 relative py-1 px-3 -my-1 -mx-3"
                gap={10}
              >
                <Search
                  search={search}
                  onSearch={setSearch}
                  placeholder={'Search ' + selectedTab}
                />
                <Button variant={'primary'} onClick={handleComposeClick}>
                  <Icon name={IconName.draft} className="mr-1" />
                  Compose
                </Button>
              </Flex>
            </Flex>
          }
          tab={selectedTab}
          onTabSelect={handleTabSelect}
        >
          {
            tabs.map(({ tab, icon, hidden }) => (
              <Tabs.Pane
                key={tab}
                tab={
                  <Flex
                    gap={8}
                    className={clsx(
                      'text-grey-600',
                      selectedTab === tab && 'text-grey-900'
                    )}
                  >
                    <Icon name={icon} className="mx-0.5" />
                    {capitalize(tab)}
                  </Flex>
                }
                id={tab}
                className="mr-0.5"
                group={hidden ? 'more' : undefined}
              >
                <ThreadsTab
                  tab={tab}
                  search={search}
                  onRowClick={handleRowClick}
                  checkboxes={checkboxes}
                  onCheckboxesChange={setCheckboxes}
                />
              </Tabs.Pane>
            )) as any
          }
        </Tabs>
      </Flex>
    </MainContent>
  )
}

export { Threads }
