import { Flex } from 'components/Flex'
import { SelectedPerson } from './ModalAttachDocs'
import { PersonItem } from './PersonItem'

interface Props {
  loans: SelectedPerson[]
  contacts: SelectedPerson[]
  onSelectPerson: (person: SelectedPerson) => void
}

const ListOfPersons = ({ loans, contacts, onSelectPerson }: Props) => {
  return (
    <Flex stack gap={12}>
      <div className="text-sm text-grey-600 leading-[18px]">
        {loans.length ? 'Linked Loans' : 'No Loans'}
      </div>
      <div>
        {loans.map((loan) => (
          <PersonItem
            key={loan.id}
            name={loan.name}
            onClickItem={() => onSelectPerson(loan)}
          />
        ))}
      </div>
      <div className="text-sm text-grey-600 leading-[18px]">
        {contacts.length ? 'Contacts' : 'No Contacts'}
      </div>
      <div>
        {contacts.map((contact) => (
          <PersonItem
            key={contact.id}
            name={contact.name}
            onClickItem={() => onSelectPerson(contact)}
          />
        ))}
      </div>
    </Flex>
  )
}

export { ListOfPersons }
