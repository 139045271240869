import { Button } from 'components/Button'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import { TextLink } from 'components/TextLink'
import { formatUsd } from 'utils/currency'
import styles from './styles.module.scss'

interface Props {
  investmentClassName: string
  amount: string
  cashBalance: number
  investmentAccountName: string
  saving: boolean
  onBack: () => void
  onCancel: () => void
  onNext: () => void
}

const InvestmentConfirmationForm = ({
  investmentClassName,
  amount,
  cashBalance,
  investmentAccountName,
  saving,
  onBack,
  onCancel,
  onNext,
}: Props) => {
  return (
    <div className={styles.agreementContainer}>
      <Grid className={styles.form} gap={24}>
        <Grid.Item xs={12} sm={6}>
          <Text className={styles.confirmationHeading}>Investment Class</Text>
          <Text>{investmentClassName}</Text>
        </Grid.Item>
        <Grid.Item xs={12} sm={6}>
          <Text className={styles.confirmationHeading}>Amount</Text>
          <Text>{formatUsd(amount, { showZero: true })}</Text>
        </Grid.Item>
        <Grid.Item xs={12} sm={6}>
          <Text className={styles.confirmationHeading}>Account Balance</Text>
          <Text>{formatUsd(cashBalance, { showZero: true })}</Text>
        </Grid.Item>
        <Grid.Item xs={12} sm={6}>
          <Text className={styles.confirmationHeading}>Investment Account</Text>
          <Text>{investmentAccountName}</Text>
        </Grid.Item>
        <Grid.Item xs={12}>
          <div className={styles.buttonsWithBack}>
            <TextLink variant="invert" onClick={onBack}>
              <Icon name={IconName.arrowLeft} size="sm" />
              Previous
            </TextLink>
            <div className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" loading={saving} onClick={onNext}>
                Confirm
              </Button>
            </div>
          </div>
        </Grid.Item>
      </Grid>
    </div>
  )
}

export default InvestmentConfirmationForm
