import { createMongoAbility } from '@casl/ability'
import { createCanBoundTo } from '@casl/react'
import { createContext } from 'react'
import { useSessionManager } from 'hooks/use-session-manager'

export const SessionContext = createContext<
  Omit<ReturnType<typeof useSessionManager>, 'invalidate'>
>({
  signIn: () => {},
  start: () => {},
  signOut: () => {},
  refreshUser: () => {},
  isSigningIn: false,
  isLoading: true,
  isSignedIn: true,
  isSignedOut: false,
  isIndividual: false,
  isAdmin: false,
  isAdminManager: false,
  isManager: false,
  user: null,
  errors: [],
  borrowerAccounts: [],
  ability: createMongoAbility([]),
  Can: createCanBoundTo(createMongoAbility([])),
  getAbilityCondition: () => ({}),
})
