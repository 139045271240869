import { compact } from 'lodash'
import { useEffect } from 'react'
import { Line } from 'react-chartjs-2'
import { useChartReport } from 'admin/hooks/use-report'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import theme from 'styles/theme'
import { formatDate } from 'utils/date'
import { ReportChartData } from '../../types'
import { datasetOptions, lineDefaultOptions, registerLineChart } from './chart'
import styles from './styles.module.scss'

interface Params {
  enabled?: boolean
  variant?: 'secondary'
  onLoad?: () => void
  onClick?: () => void
}

function WidgetLoansPerMonth({
  enabled = true,
  variant,
  onLoad,
  onClick,
}: Params) {
  const {
    data: report,
    isPending,
    isSuccess,
  } = useChartReport('loans-per-month', {
    enabled,
  })
  useEffect(() => {
    if (onLoad && isSuccess) {
      onLoad()
    }
  }, [isSuccess])

  useEffect(() => {
    registerLineChart()
  }, [])

  const isEmptyChart = !compact(
    (report as unknown as ReportChartData)?.map(
      ({ maturity }) => (maturity && parseInt(maturity)) || 0
    )
  ).length
  const beginAtZero = isEmptyChart
  const max = isEmptyChart ? 10 : undefined

  const options = {
    ...lineDefaultOptions,
    scales: {
      ...lineDefaultOptions.scales,
      y: {
        ...lineDefaultOptions.scales.y,
        beginAtZero,
        max,
      },
    },
  }

  const data = {
    labels: (report as unknown as ReportChartData)?.map(({ month }) =>
      formatDate(month, 'MMM yy')
    ),
    datasets: [
      {
        label: 'Closing Loans',
        data: (report as unknown as ReportChartData)?.map(
          ({ closing }) => closing
        ),
        borderColor: theme.color.blue200,
        backgroundColor: theme.color.blue200,
        ...datasetOptions,
      },
      {
        label: 'Maturing Loans',
        data: (report as unknown as ReportChartData)?.map(
          ({ maturity }) => maturity
        ),
        borderColor: theme.color.green100,
        backgroundColor: theme.color.green100,
        ...datasetOptions,
      },
    ],
  }

  return (
    <div className={styles.widget} onClick={onClick}>
      {isPending ? (
        <div className={styles.loader}>
          <Icon name={IconName.loaderSpinner} className="spinner" size="xl" />
        </div>
      ) : (
        <Flex stack gap={24} className={styles.widgetChart}>
          {variant ? (
            <div className={styles[`title${variant}`]}>Loans Per Month</div>
          ) : (
            <Header variant="h2">Loans Per Month</Header>
          )}

          <div className={styles.chart}>
            <Line options={options} data={data} />
          </div>
        </Flex>
      )}
    </div>
  )
}

export { WidgetLoansPerMonth }
