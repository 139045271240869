import { TOGGLE_LINK_COMMAND, $isLinkNode } from '@lexical/link'
import { mergeRegister } from '@lexical/utils'
import { $getSelection, $isRangeSelection, LexicalEditor } from 'lexical'
import { useCallback, useEffect, useState } from 'react'
import { InsertImageDialog } from 'admin/components/InlineWysiwyg/plugins/ImagePlugin'
import { getSelectedNode } from 'admin/components/InlineWysiwyg/utils/getSelectedNode'
import { ControlFontButton } from 'admin/components/ThreadForm/ControlFontButton'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'

interface Props {
  editor: LexicalEditor
  onSend: () => void
  onAttachingDocs: () => void
  onDelete: () => void
}

const Controls = ({ editor, onSend, onAttachingDocs, onDelete }: Props) => {
  const [isLink, setIsLink] = useState(false)
  const [isImageModalOpen, setIsImageModalOpen] = useState(false)

  const updateToolbar = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      // Update links
      const node = getSelectedNode(selection)
      const parent = node.getParent()
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true)
      } else {
        setIsLink(false)
      }
    }
  }, [editor])

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://')
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
    }
  }, [editor, isLink])

  const showImageDialog = useCallback(() => {
    setIsImageModalOpen(true)
  }, [editor])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar()
        })
      })
    )
  }, [editor, updateToolbar])

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        className="py-3 px-6 border-0 border-t border-solid border-grey-200"
      >
        <Flex gap={4} alignItems="center">
          <Button variant="primary" onClick={onSend}>
            <Icon name={IconName.sendThread} />
            Send
          </Button>
          <ControlFontButton editor={editor} />
          <Button variant="ghost" onClick={onAttachingDocs} className="w-8">
            <Icon
              name={IconName.paperClipVertical}
              size="md"
              className="text-grey-600"
            />
          </Button>
          <Button
            variant="ghost"
            active={isLink}
            onClick={insertLink}
            className="w-8"
          >
            <Icon
              name={IconName.linkHorizontal}
              size="md"
              className="text-grey-600"
            />
          </Button>
          <Button variant="ghost" onClick={showImageDialog} className="w-8">
            <Icon name={IconName.image} size="md" className="text-grey-600" />
          </Button>
          <Button
            variant="ghost"
            onClick={() => console.log('Pen')}
            className="w-8"
          >
            <Icon name={IconName.pen} size="md" className="text-grey-600" />
          </Button>
          <Flex
            gap={4}
            className="pl-1 border-0 border-l border-solid border-grey-200"
          >
            <Button variant="ghost" onClick={() => console.log('Add field')}>
              <Icon
                name={IconName.mergeField}
                size="md"
                className="text-grey-600"
              />
              Add field
            </Button>
          </Flex>
        </Flex>
        <Button variant="ghost" onClick={onDelete} className="w-8">
          <Icon name={IconName.delete} size="md" className="text-grey-600" />
        </Button>
      </Flex>
      {isImageModalOpen && (
        <InsertImageDialog
          activeEditor={editor}
          onClose={() => setIsImageModalOpen(false)}
        />
      )}
    </>
  )
}

export { Controls }
