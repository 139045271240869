import { useCallback, useState } from 'react'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { friendlyMime, friendlySize } from 'utils/file'

const Attachments = () => {
  const [documents, setDocuments] = useState([
    {
      id: '1c23b037-6fdd-4325-b4b4-a6cf85418779',
      name: 'Dutch v.s. Non-Dutch Interest Test Loan - 10/05/2023',
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      size: 16405,
    },
    {
      id: '171207ee-d4a1-4f57-8908-833d61c4dd82',
      name: 'Entity Status',
      type: 'image/png',
      size: 60445,
    },
  ])

  const handleRemove = useCallback((id: string) => {
    setDocuments((prev) => prev.filter((doc) => doc.id !== id))
  }, [])

  return (
    <Flex gap={10} flexWrap="wrap" className="px-6 pt-4">
      {documents.map((document) => (
        <Flex
          key={document.id}
          gap={10}
          alignItems="center"
          justifyContent="space-between"
          className="max-w-48 p-1 pr-2.5 pl-1.5 bg-grey-75 text-sm leading-[18px] rounded cursor-pointer hover:bg-grey-100"
        >
          <Flex gap={4} className="truncate">
            <Flex alignItems="center">
              {['PNG', 'JPEG', 'JPG'].includes(friendlyMime(document.type)) ? (
                <Icon
                  name={IconName.image}
                  size="md"
                  className="text-grey-600"
                />
              ) : (
                <Icon
                  name={IconName.paperClipVertical}
                  size="md"
                  className="text-grey-600"
                />
              )}
            </Flex>
            <div>{friendlyMime(document.type)}</div>
            <div className="text-grey-900 font-bold truncate">
              {document.name}
            </div>
            <div className="text-grey-600">{friendlySize(document.size)}</div>
          </Flex>
          <Flex
            alignItems="center"
            onClick={(e) => {
              e.stopPropagation()
              handleRemove(document.id)
            }}
          >
            <Icon
              name={IconName.close}
              size="md"
              className="text-grey-600 hover:text-grey-800"
            />
          </Flex>
        </Flex>
      ))}
    </Flex>
  )
}

export { Attachments }
