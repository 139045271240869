import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { KEY_DOCUMENTS } from 'constants/query-keys'
import {
  getDocumentUploadUrl,
  getDocumentVersion,
  uploadDocument,
} from 'services/api/document'
import {
  getInvestorDocuments,
  addInvestorDocument,
} from 'services/api/investor-documents'
import { handleErrorResponse } from 'services/request'
import { LoanDocument } from 'types'
import { message } from 'utils/message'

const useInvestorDocuments = (
  {
    id,
    isBorrower,
  }: {
    id: string
    isBorrower?: boolean
  },
  options?: { enabled: boolean }
) => {
  return useQuery({
    queryKey: [KEY_DOCUMENTS],
    queryFn: () => getInvestorDocuments({ id, isBorrower }),
    ...options,
  })
}

const useAddInvestorDocument = ({ id }: { id: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (document: Partial<LoanDocument>) =>
      addInvestorDocument(id, document),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_DOCUMENTS] })
      message.success('Document created')
    },
    onError: handleErrorResponse,
  })
}

const useAddInvestorDocuments = ({ id }: { id: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (files: FileList) => {
      return Promise.all(
        Array.from(files).map(async (file) => {
          const name = file.name.replace(/\.[^/.]+$/, '')
          const uploadToast = message.upload(name)
          uploadToast.show()
          const newDocument = await addInvestorDocument(id, { name })
          const { url } = await getDocumentUploadUrl({
            id: newDocument.id,
            filename: file.name,
            type: file.type,
          })
          const { headers } = await uploadDocument(
            url,
            file,
            (progressEvent) => {
              uploadToast.progress(progressEvent.loaded / progressEvent.total)
            }
          )
          uploadToast.processing()
          const versionId = headers['x-amz-version-id']
          const version = await getDocumentVersion(
            newDocument.id,
            versionId,
            true
          )
          uploadToast.complete()
          return version
        })
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_DOCUMENTS] })
      message.success('Documents added')
    },
    onError: handleErrorResponse,
  })
}

export { useInvestorDocuments, useAddInvestorDocument, useAddInvestorDocuments }
