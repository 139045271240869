import clsx from 'clsx'
import { Fragment, useMemo } from 'react'
import { useLoanPayment } from 'admin/hooks/use-loan-payment'
import { Avatar } from 'components/Avatar'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { MetaFields } from 'components/MetaFields'
import { Modal } from 'components/Modal'
import { Redact } from 'components/Redact'
import { Text } from 'components/Text'
import { useSession } from 'hooks/use-session'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'
import { getCalculations } from 'utils/payment'
import styles from './styles.module.scss'

interface Props {
  loanId: string
  paymentId: string
  onCancel: () => void
}

function PaymentDetails({ loanId, paymentId, onCancel }: Props) {
  const { user, ability } = useSession()
  const { data: payment, isPending } = useLoanPayment({
    loanId,
    paymentId,
  })

  const { sources, distributions } = useMemo(
    () => getCalculations(payment),
    [payment]
  )

  return (
    <Modal
      asChild
      loading={isPending}
      title={`${payment?.type} Details`}
      onClose={onCancel}
      className={styles.modal}
      crossClose
    >
      <Flex stack gap={16} className={styles.container}>
        <MetaFields
          className="mx-0"
          fields={[
            ...(payment?.type === 'Regular Payment'
              ? [
                  {
                    key: 'Due',
                    value:
                      (payment?.datePaidto &&
                        formatDate(payment?.datePaidto)) ||
                      '-',
                  },
                ]
              : []),
            ...(payment?.type === 'Special Payment'
              ? [
                  {
                    key: 'Payment Through',
                    value:
                      (payment?.datePaidto &&
                        formatDate(payment?.datePaidto)) ||
                      '-',
                  },
                ]
              : []),
            {
              key: payment?.type === 'To Borrower' ? 'Sent' : 'Received',
              value: (payment?.date && formatDate(payment?.date)) || '-',
            },
            {
              key: 'Release After',
              value:
                ((payment?.dateRelease || payment?.date) &&
                  formatDate(payment?.dateRelease || payment?.date)) ||
                '-',
            },
            ...(payment?.method
              ? [
                  {
                    key: 'Method',
                    value: payment?.method || '-',
                  },
                ]
              : []),
          ]}
        />
        <div className={styles.details}>
          <div className={styles.block}>
            <Header variant="h4">Source</Header>
            <div className={styles.table}>
              {sources.map(({ name, value }) => (
                <Fragment key={name}>
                  <div>
                    <Redact
                      value={name}
                      hide={
                        ['Funding', 'Transfer'].includes(payment?.type ?? '') &&
                        ability.cannot('read', 'investors')
                      }
                    />
                  </div>
                  <div className={styles.amount}>{formatUsd(value)}</div>
                </Fragment>
              ))}
            </div>
          </div>
          <div className={styles.block}>
            <Header variant="h4">Distribution</Header>
            <div className={styles.table}>
              {distributions.map(({ name, value }) => (
                <Fragment key={name}>
                  <div>{name}</div>
                  <div className={styles.amount}>{formatUsd(value)}</div>
                </Fragment>
              ))}
            </div>
          </div>
        </div>
        {payment?.notes && (
          <Flex
            className={clsx(
              payment.admin?.id !== user?.admin?.id && '!flex-row-reverse'
            )}
          >
            <Flex
              stack
              gap={8}
              className="flex-grow rounded border border-solid border-yellow-100 bg-yellow-50 p-4"
            >
              <div>{payment?.notes}</div>
              {!payment.isCollected && !!payment.admin?.name && (
                <Text variant="s" className="text-grey-900">
                  {payment.admin?.name}
                </Text>
              )}
            </Flex>
            {!payment.isCollected && !!payment.admin?.name && (
              <Avatar name={payment.admin?.name} />
            )}
          </Flex>
        )}
      </Flex>
    </Modal>
  )
}

export default PaymentDetails
