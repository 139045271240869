import { omitBy, size } from 'lodash'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ImportEntity } from 'admin/components/ImportEntity'
import PageTop from 'admin/components/PageTop/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { TableVendors } from 'admin/components/table/TableVendors'
import {
  useVendors,
  useAddVendor,
  useRemoveVendor,
  useVendorFilters,
} from 'admin/hooks/use-vendors'
import { ModalAddVendor } from 'admin/pages/Vendors/ModalAddVendor'
import { pathTo } from 'admin/path-to'
import { NewVendor, Vendor } from 'admin/services/api/vendors'
import { downloadVendors } from 'admin/services/csv/download-vendors'
import { Button } from 'components/Button'
import { Download } from 'components/Download'
import { Flex } from 'components/Flex'
import { LoadMore } from 'components/LoadMore'
import { ModalDelete } from 'components/Modal/Delete'
import { Panel } from 'components/Panel'
import { Search } from 'components/Search'
import { VENDOR_IMPORT_BANNER } from 'constants/local-storage-keys'
import { usePagination } from 'hooks/use-pagination'
import { AlertVendors } from './AlertVendors'
import { EmptyVendors } from './EmptyVendors'

function Vendors() {
  const {
    visibleItems,
    result,
    search,
    filter,
    isEmpty,
    setSearch,
    setFilter,
    setPagination,
    resetPagination,
    sort,
    setSort,
    updateItem,
  } = usePagination<Vendor>({
    property: 'vendors',
    useData: (params) => useVendors({ ...params, details: true }),
  })

  const [adding, setAdding] = useState(false)
  const [removingId, setRemovingId] = useState<string | null>(null)
  const [alertData, setAlertData] = useState<string | null | boolean>(
    localStorage.getItem(VENDOR_IMPORT_BANNER)
  )
  const vendorFilters = useVendorFilters(['role'])
  const add = useAddVendor()
  const remove = useRemoveVendor({ exact: false })
  const navigate = useNavigate()
  const handleFilterChange = useCallback(
    (newFilter) => {
      setFilter(omitBy({ ...filter, ...newFilter }, (value) => !size(value)))
    },
    [filter]
  )

  const handleRowClick = useCallback((vendor?: Vendor) => {
    if (vendor && vendor.id) {
      navigate(pathTo('vendor', vendor.id))
    }
  }, [])

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop title="Vendors" />
        {alertData && (
          <AlertVendors alertData={alertData} setAlertData={setAlertData} />
        )}
        {isEmpty ? (
          <EmptyVendors
            onClick={() => setAdding(true)}
            onImport={() => setAlertData(true)}
          />
        ) : (
          <Panel>
            <div className="flex justify-center gap-2.5 pb-4 sm:justify-end">
              <Search search={search} onSearch={setSearch} />
              <ImportEntity
                entityType="vendor"
                setAlertData={() => setAlertData(true)}
              />
              <Download
                filename="vendors"
                download={() => downloadVendors(undefined, search)}
              />
              <Button variant="primary" onClick={() => setAdding(true)}>
                Add Vendor
              </Button>
            </div>
            <TableVendors
              onDelete={setRemovingId}
              data={visibleItems}
              loading={result.isPending}
              filter={filter}
              filters={vendorFilters.data}
              onFilter={handleFilterChange}
              onClick={handleRowClick}
              sort={sort}
              onSort={setSort}
              onUpdateItem={updateItem}
            />
            <LoadMore
              loading={result.isPending}
              count={visibleItems.length}
              meta={result.data?.meta}
              onLoadMore={setPagination}
            />
          </Panel>
        )}
      </Flex>

      {adding ? (
        <ModalAddVendor
          onSave={(vendor) => {
            add.mutate(vendor as NewVendor, {
              onSuccess: ({ id }) => {
                navigate(pathTo('vendor', id))
              },
            })
          }}
          onCancel={() => {
            setAdding(false)
          }}
        />
      ) : null}
      {removingId && (
        <ModalDelete
          resource="vendor"
          name={
            visibleItems?.find((vendor) => vendor.id === removingId)?.name ||
            visibleItems?.find((vendor) => vendor.id === removingId)
              ?.companyName
          }
          loading={remove.isPending}
          onDelete={() =>
            remove.mutate(removingId, {
              onSettled: () => {
                resetPagination()
                setRemovingId(null)
              },
            })
          }
          onCancel={() => setRemovingId(null)}
        />
      )}
    </MainContent>
  )
}

export { Vendors }
