import { getPayouts } from 'admin/services/api/payouts'
import { Filter, Row, Sort, Transaction } from 'types'
import { getFundingType } from 'utils/loan-transactions'

const downloadPayouts = async (
  filter?: Filter,
  sort?: Sort,
  search?: string
): Promise<Row[]> => {
  const { transactions } = await getPayouts({
    filter,
    search,
    sort,
    page: {
      size: 1000,
      page: 0,
    },
  })

  // Update in TablePayouts too

  return transactions.map((transaction) => ({
    Date: transaction.date,
    Loan: transaction.loan?.name,
    Recipient: transaction.person?.name,
    Type: getFundingType(transaction as unknown as Transaction),
    Class: transaction.fundingSource?.class,
    Amount$: transaction.amount,
    Status: transaction.payment?.status || 'cleared',
  }))
}

export { downloadPayouts }
