import {
  DOMConversionMap,
  ElementNode,
  LexicalNode,
  SerializedElementNode,
  Spread,
  $createParagraphNode,
  $isRangeSelection,
  $isBlockElementNode,
} from 'lexical'

export type SerializedDivNode = Spread<
  {
    type: 'div'
    version: 1
    className?: string
  },
  SerializedElementNode
>

export class DivNode extends ElementNode {
  __className?: string

  constructor(key?: string, className?: string) {
    super(key)
    this.__className = className
  }

  static getType(): string {
    return 'div'
  }

  static clone(node: DivNode): DivNode {
    return new DivNode(node.__key, node.__className)
  }

  createDOM(): HTMLElement {
    const dom = document.createElement('div')
    if (this.__className) {
      dom.setAttribute('class', this.__className)
    }
    return dom
  }

  updateDOM(): boolean {
    return false
  }

  static importDOM(): DOMConversionMap {
    return {
      div: () => ({
        conversion: (domNode) => ({ node: convertDivElement(domNode) }),
        priority: 1,
      }),
    }
  }

  static importJSON(serializedNode: SerializedDivNode): DivNode {
    return $createDivNode(serializedNode.className)
  }

  exportJSON(): SerializedDivNode {
    return {
      ...super.exportJSON(),
      type: 'div',
      version: 1,
      className: this.__className,
    }
  }

  insertNewAfter(selection, restoreSelection = true) {
    if (!$isRangeSelection(selection)) {
      return super.insertNewAfter(selection, restoreSelection)
    }

    const newParagraph = $createParagraphNode()
    const anchor = selection.anchor
    const focusNode = anchor.getNode()

    if ($isBlockElementNode(focusNode.getNextSibling())) {
      focusNode.getNextSibling()?.insertBefore(newParagraph)
      newParagraph.select(0, 0)
    } else {
      this.append(newParagraph)
      return newParagraph
    }

    return null
  }
}

function convertDivElement(domNode: HTMLElement) {
  return $createDivNode(domNode.className)
}

export function $createDivNode(className?: string): DivNode {
  return new DivNode(undefined, className)
}

export function $isDivNode(
  node: LexicalNode | null | undefined
): node is DivNode {
  return node instanceof DivNode
}
