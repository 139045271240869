import { uniqBy } from 'lodash'
import { useMemo } from 'react'
import CreatableSelect from 'react-select/creatable'
import { usePersons } from 'admin/hooks/use-persons'
import { IMail, IThread } from 'types'

interface Props {
  people: IThread['people']
  recipients: IMail['to']
  onChange: (recipients: IMail['to']) => void
  className?: string
}

const RecipientsSelector = ({
  people,
  recipients,
  onChange,
  className,
}: Props) => {
  const { data } = usePersons({
    filter: {
      email: [['like', '@']],
    },
  })

  const value = useMemo(
    () =>
      recipients.map((recipient) => ({
        label: recipient.name,
        value: recipient.email,
      })),
    [recipients]
  )
  const options = useMemo(() => {
    const peopleOptions = uniqBy(
      [...people, ...(data?.people?.filter((p) => !!p.email) || [])].map(
        (person) => ({
          label: person.name,
          value: person.email,
        })
      ),
      'value'
    )
    value.forEach((value) => {
      if (!peopleOptions.find((person) => person.value === value.value)) {
        peopleOptions.push({ label: value.label, value: value.value })
      }
    })
    return peopleOptions
  }, [people, value, data])

  return (
    <CreatableSelect
      className={className}
      classNames={{
        control: () => '!border-transparent !shadow-none !min-h-[42px]',
        indicatorsContainer: () => '!hidden',
        multiValue: () =>
          '!bg-white-100 border border-solid border-grey-200 !rounded h-8',
        multiValueLabel: () => 'leading-6 !pl-2 !text-base',
      }}
      options={options}
      value={value}
      isClearable={false}
      onChange={(nextRecipients) => {
        onChange(
          nextRecipients.map((recipient) => ({
            name: recipient.label,
            email: recipient.value,
          }))
        )
      }}
      formatCreateLabel={(inputValue) => inputValue}
      placeholder="Recipients"
      isMulti
    />
  )
}

export { RecipientsSelector }
