import {
  autoUpdate,
  flip,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
  size,
} from '@floating-ui/react'
import { useState, useCallback } from 'react'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'

interface Props {
  isRead: boolean
  onToggleRead: () => void
}

const HeaderActions = ({ isRead, onToggleRead }: Props) => {
  const [open, setOpen] = useState(false)

  const handleOpenChange = useCallback((nextOpen: boolean) => {
    setOpen(nextOpen)
  }, [])

  const { x, y, strategy, context, refs } = useFloating({
    open,
    onOpenChange: handleOpenChange,
    whileElementsMounted: autoUpdate,
    placement: 'bottom-end',
    middleware: [
      flip(),
      size({
        apply({ elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
          })
        },
      }),
    ],
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [
      useClick(context, { event: 'click' }),
      useDismiss(context),
      useRole(context, { role: 'tree' }),
    ]
  )

  return (
    <>
      <Flex
        className="w-8 h-8 rounded cursor-pointer hover:bg-grey-100"
        {...getReferenceProps({
          ref: refs.setReference,
          onClick(e) {
            e.stopPropagation()
          },
        })}
      >
        <Icon
          name={IconName.ellipses}
          size="md"
          className="m-auto text-grey-700"
        />
      </Flex>
      {open && (
        <FloatingPortal>
          <Flex
            stack
            gap={0}
            className="min-w-[170px] p-1 bg-white-100 shadow-300 rounded py-1 border-solid border-[1px] border-grey-200"
            onClick={(e) => e.stopPropagation()}
            {...getFloatingProps({
              ref: refs.setFloating,
              style: {
                position: strategy,
                top: y + 6,
                left: x ?? 0,
                zIndex: 5,
              },
            })}
          >
            <Flex
              gap={8}
              alignItems="center"
              className="py-1.5 px-2 rounded-sm hover:bg-grey-75 cursor-pointer"
              {...getItemProps({
                onClick: () => {
                  setOpen(false)
                  onToggleRead()
                },
              })}
            >
              {/* <Icon
                name={IconName.unread} // TODO no icon in figma
                size="md"
                className="text-grey-600"
              /> */}
              <div className="leading-5 text-grey-900">
                {isRead ? 'Unread' : 'Mark as Read'}
              </div>
            </Flex>
          </Flex>
        </FloatingPortal>
      )}
    </>
  )
}

export { HeaderActions }
