import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { usePayoutFilters } from 'admin/hooks/use-payout'
import { downloadPayouts } from 'admin/services/csv/download-payouts'
import { Download } from 'components/Download'
import {
  Filter,
  filterValueToTableFilter,
  filterValueToUrl,
  IFilterConfig,
  IFilterValue,
  urlToFilterValue,
} from 'components/Filter'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Search } from 'components/Search'
import { Sort } from 'types'

interface Props {
  title: string
  tab: 'completed' | 'ready'
  search: string | undefined
  sort: Sort | undefined
  onSearch: Dispatch<SetStateAction<string | undefined>>
}

const PanelTopBar = ({ title, tab, search, sort, onSearch }: Props) => {
  const { data: availableFilters } = usePayoutFilters(['funding_source_class'])
  const [searchParams, setSearchParams] = useSearchParams()
  const filtersValue = useMemo(
    () =>
      searchParams.get('filter')
        ? urlToFilterValue(searchParams.get('filter') as string)
        : [],
    [searchParams]
  )
  const handleFilterChange = useCallback(
    (value: IFilterValue) => {
      setSearchParams({ filter: filterValueToUrl(value) }, { replace: true })
    },
    [setSearchParams]
  )

  const filterConfig: IFilterConfig[] = useMemo(
    () => [
      {
        id: 'date',
        type: 'date',
        label: 'Date',
      },
      {
        id: 'type',
        type: 'select',
        label: 'Type',
        options: [
          { value: 'Charge', label: 'Charge' },
          { value: 'Interest', label: 'Interest' },
          {
            value: 'Returned Capital',
            label: 'Returned Capital',
          },
          { value: 'Spread', label: 'Spread' },
        ],
      },
      {
        id: 'fundingSourceClass',
        type: 'select',
        label: 'Class',
        options: availableFilters?.fundingSourceClass?.map(
          (fundingSourceClass: string) => ({
            value: fundingSourceClass,
            label: fundingSourceClass,
          })
        ),
      },
      ...(tab === 'ready'
        ? [
            {
              id: 'payments.status',
              type: 'select',
              label: 'Status',
              options: [
                { value: 'cleared', label: 'Ready' },
                { value: 'pending', label: 'Clearing' },
              ],
            } as IFilterConfig,
          ]
        : [
            {
              id: 'isPaidout',
              type: 'select',
              label: 'Status',
              options: [
                { value: 'true', label: 'Paid Out' },
                { value: 'false', label: 'Pending' },
              ],
            } as IFilterConfig,
          ]),
    ],
    [availableFilters, tab]
  )

  return (
    <Flex
      gap={4}
      justifyContent="space-between"
      alignItems="center"
      flexWrap="wrap"
      className="pb-4"
    >
      <Header variant="h3">{title}</Header>
      <Flex gap={8}>
        <Filter
          config={filterConfig}
          value={filtersValue}
          onApply={handleFilterChange}
        />
        <Search search={search} onSearch={onSearch} />
        <Download
          filename="payouts"
          download={() =>
            downloadPayouts(
              {
                ...filterValueToTableFilter(filtersValue),
                completed: [tab === 'completed'],
              },
              sort,
              search
            )
          }
        />
      </Flex>
    </Flex>
  )
}

export { PanelTopBar }
