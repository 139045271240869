import { RawRule } from '@casl/ability'
import { isEqual, omit } from 'lodash'
import { useMemo, useState, useEffect, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { MainContent } from 'admin/components/layout/MainContent'
import { useProducts } from 'admin/hooks/use-products'
import { useRoles, useUpdateRole } from 'admin/hooks/use-roles'
import { useUsers } from 'admin/hooks/use-users'
import styles from 'admin/pages/Quote/styles.module.scss'
import { pathTo } from 'admin/path-to'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Input } from 'components/Input'
import { Panel } from 'components/Panel'
import {
  options as defaultOptions,
  ExtendedRawRule,
} from 'constants/permissions'
import { useSession } from 'hooks/use-session'
import { CurrentUser } from 'services/api/session'
import { Role } from 'types'
import { SelectUser } from './ModalRole/SelectUser'
import {
  PermissionChooser,
  PermissionOption,
} from './Permissions/PermissionChooser'

function removeOppositeRule(rules: RawRule[]) {
  let newRules = [...rules].reverse()

  for (let i = 0; i < newRules.length; i++) {
    const rule = newRules[i]
    newRules = newRules.filter((r) => {
      if (
        r.subject === rule.subject &&
        r.action === rule.action &&
        isEqual(r.conditions ?? {}, rule.conditions ?? {}) &&
        !!r.inverted !== !!rule.inverted
      ) {
        return false
      }
      if (
        r.subject === rule.subject &&
        r.action === rule.action &&
        !!r.conditions &&
        !rule.conditions
      ) {
        return false
      }
      if (
        r.subject === rule.subject &&
        r.action === rule.action &&
        !!r.conditions &&
        !!rule.conditions &&
        !isEqual(r.conditions ?? {}, rule.conditions ?? {})
      ) {
        return false
      }
      return true
    })
  }

  return newRules.reverse()
}

function RoleEdit() {
  const navigate = useNavigate()
  const session = useSession()
  const { user } = session
  const { id } = useParams() as {
    id: string
  }
  const { data, isPending } = useRoles()
  const { data: allUsers } = useUsers({
    user,
    clientId: (user as CurrentUser)?.client?.id,
  })

  const { data: products, isPending: isProductPending } = useProducts()

  const role = useMemo(() => data?.find((role) => role.id === id), [data, id])

  const [name, setName] = useState(role?.name || '')
  const [admins, setAdmins] = useState(role?.admins?.map(({ id }) => id) || [])

  const currentUserAffected = useMemo(() => {
    return role?.admins?.map(({ id }) => id).includes(user?.admin?.id ?? '')
  }, [role, user])

  useEffect(() => {
    if (isPending || isProductPending) {
      return
    }
    setName(role?.name || '')
    setAdmins(role?.admins?.map(({ id }) => id) || [])
    setPermissions(role?.permissions || [])
  }, [isPending, role, products])

  const { mutate: update, isPending: isUpdating } = useUpdateRole()

  const [permissions, setPermissions] = useState<RawRule[]>([])

  const handleSelection = ({ permissions }) => {
    setPermissions(removeOppositeRule(permissions))
  }

  const handleSubmit = useCallback(() => {
    if (!role) {
      return
    }
    update(
      {
        id: role.id,
        name,
        admins: admins as unknown as Role['admins'],
        permissions: permissions,
      },
      {
        onSuccess: () => {
          navigate(pathTo('settingsRoles'))
          if (admins.includes(user?.admin?.id ?? '') || currentUserAffected) {
            session.refreshUser()
          }
        },
      }
    )
  }, [update, role, name, admins, permissions])

  const options = useMemo(() => {
    let newPermissions = [...permissions] as ExtendedRawRule[]

    function isChecked(permission) {
      return permission.every((p) =>
        newPermissions?.some(
          (p1) =>
            isEqual(omit(p, 'conditions'), omit(p1, 'conditions')) &&
            !!p.conditions === !!p1.conditions
        )
      )
    }

    let isPermissionsModified = false

    const newOptions = defaultOptions.reduce<
      { name: string; options: PermissionOption[] }[]
    >((acc, option) => {
      const { name, options } = option as {
        name: string
        options: PermissionOption[]
      }
      const checkedOptions = options.map((option) => ({
        ...option,
        checked: isChecked(option.permission),
      }))

      if (!checkedOptions.find((option) => option.checked)) {
        checkedOptions[0].checked = true
        newPermissions = [
          ...newPermissions,
          ...(checkedOptions[0].permission as ExtendedRawRule[]),
        ]
        isPermissionsModified = true
      } else {
        const checked = checkedOptions.find((option) => option.checked)
        if (checked?.select) {
          const selectedProducts = newPermissions.find(
            (p) =>
              p.subject === checked.permission[0].subject &&
              p.action === checked.permission[0].action
          )?.conditions
          checkedOptions[1].permission[0].conditions = selectedProducts
        }
      }

      return [
        ...acc,
        {
          name,
          options: checkedOptions,
        },
      ]
    }, [])

    if (isPermissionsModified && !isPending && !isProductPending) {
      setPermissions(newPermissions)
    }

    return newOptions as unknown as Array<{
      name: string
      options: PermissionOption[]
    }>
  }, [permissions, isPending, isProductPending])

  return (
    <Flex stack gap={0} className={styles.container}>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        className={styles.header}
      >
        <Breadcrumbs
          breadcrumbs={{ title: 'Roles', link: pathTo('settingsRoles') }}
        />
        <Button variant="tertiary" loading={isUpdating} onClick={handleSubmit}>
          Save & Exit
        </Button>
      </Flex>
      <MainContent>
        <Flex stack gap={16}>
          <Header variant="h1" className="mt-4">
            {name}
          </Header>
          <div className="max-w-panel">
            <Flex stack gap={16}>
              <Panel title="Role Name">
                <Input
                  type="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter name"
                />
              </Panel>
              <Panel title="Assigned To">
                <Flex stack gap={6}>
                  <SelectUser
                    options={
                      allUsers?.map((user) => ({
                        value: user.id,
                        label: user.name,
                        email: user.email,
                      })) || []
                    }
                    onChange={(value) => setAdmins(value)}
                    value={admins}
                  />
                  <div className="text-grey-700">
                    Choose which teammates should have this role assigned to
                    them
                  </div>
                </Flex>
              </Panel>
              <Panel title="Permissions">
                <Flex stack gap={16}>
                  {options.map(({ name, options }) => (
                    <Panel
                      title={name}
                      headerClassName="text-xl"
                      className="bg-grey-50"
                      key={name}
                    >
                      <PermissionChooser
                        id={name}
                        permissions={permissions}
                        options={options}
                        products={products?.products}
                        onSelection={handleSelection}
                      />
                    </Panel>
                  ))}
                </Flex>
              </Panel>
            </Flex>
          </div>
        </Flex>
      </MainContent>
    </Flex>
  )
}

export { RoleEdit as Role }
