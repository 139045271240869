import { RawRule } from '@casl/ability'
import { isEqual } from 'lodash'
import { Flex } from 'components/Flex'
import { Radio } from 'components/Radio'
import { Select, Option } from 'components/Select'
import { ExtendedRawRule, KEY_SELECT_PRODUCTS } from 'constants/permissions'
import { Product } from 'types'
interface PermissionOption {
  label: string
  permission: ExtendedRawRule[]
  checked: boolean
  select: string
}

interface Props {
  id: string
  permissions: RawRule[]
  options: PermissionOption[]
  products?: Product[]
  onSelection: ({ permissions }) => void
}

function PermissionChooser({
  id,
  permissions,
  options,
  products,
  onSelection,
}: Props) {
  function handleSelection({ permission }) {
    const updatedPermissions = [
      ...options.reduce((acc, option) => {
        return acc.filter(
          (p) => !option.permission.some((p1) => isEqual(p, p1))
        )
      }, permissions || []),
      ...permission,
    ]

    onSelection({ permissions: updatedPermissions })
  }

  return (
    <Flex stack gap={6}>
      {options.map((option, i) => (
        <Flex key={`${id}-${i}-flex`} gap={4} stack>
          <Radio
            key={`${id}-${i}`}
            name={id}
            defaultValue={option.label}
            label={option.label}
            checked={option.checked}
            onChange={() => handleSelection({ permission: option.permission })}
          />
          {option.select === KEY_SELECT_PRODUCTS && option.checked && (
            <Select
              className="my-1"
              isMulti
              options={
                products?.map((product) => ({
                  label: product.name,
                  value: product.id,
                })) || []
              }
              key={`${id}-${i}-select`}
              defaultValue={option.permission[0].conditions?.id
                ?.map?.((id) => {
                  const product = products?.find((p) => p.id === id)
                  if (product) {
                    return {
                      label: product?.name,
                      value: product?.id,
                    }
                  }
                })
                .filter(Boolean)}
              onChange={(selected) =>
                handleSelection({
                  permission: option.permission.map((p) => ({
                    ...p,
                    conditions: {
                      id: (selected as unknown as Option[])
                        .map((o) => o?.value)
                        .filter(Boolean),
                    },
                  })),
                })
              }
            />
          )}
        </Flex>
      ))}
    </Flex>
  )
}

export { PermissionChooser }
export type { PermissionOption }
