import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  getBorrowerDocuments,
  addBorrowerDocument,
} from 'admin/services/api/borrower-documents'
import { KEY_DOCUMENTS } from 'constants/query-keys'
import {
  getDocumentUploadUrl,
  getDocumentVersion,
  uploadDocument,
} from 'services/api/document'
import { handleErrorResponse } from 'services/request'
import { LoanDocument } from 'types'
import { message } from 'utils/message'

const useBorrowerDocuments = (
  { id }: { id: string },
  options?: { enabled: boolean }
) => {
  return useQuery({
    queryKey: [KEY_DOCUMENTS],
    queryFn: () => getBorrowerDocuments({ id }),
    gcTime: 0,
    ...options,
  })
}

const useAddBorrowerDocument = ({ id }: { id: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (document: Partial<LoanDocument>) =>
      addBorrowerDocument(id, document),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_DOCUMENTS] })
      message.success('Document created')
    },
    onError: handleErrorResponse,
  })
}

const useAddBorrowerDocuments = ({ id }: { id: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (files: FileList) => {
      return Promise.all(
        Array.from(files).map(async (file) => {
          const name = file.name.replace(/\.[^/.]+$/, '')
          const uploadToast = message.upload(name)
          uploadToast.show()
          const newDocument = await addBorrowerDocument(id, { name })
          const { url } = await getDocumentUploadUrl({
            id: newDocument.id,
            filename: file.name,
            type: file.type,
          })
          const { headers } = await uploadDocument(
            url,
            file,
            (progressEvent) => {
              uploadToast.progress(progressEvent.loaded / progressEvent.total)
            }
          )
          uploadToast.processing()
          const versionId = headers['x-amz-version-id']
          const version = await getDocumentVersion(
            newDocument.id,
            versionId,
            true
          )
          uploadToast.complete()
          return version
        })
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_DOCUMENTS] })
      message.success('Documents added')
    },
    onError: handleErrorResponse,
  })
}

export { useBorrowerDocuments, useAddBorrowerDocument, useAddBorrowerDocuments }
