import {
  keepPreviousData,
  useQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { size } from 'lodash'
import { updateOwners as updateBorrowerOwners } from 'admin/services/api/borrowers'
import { updateOwners as updateInvestorOwners } from 'admin/services/api/investors'
import { getPersons } from 'admin/services/api/persons'
import { updateOwners as updateVendorOwners } from 'admin/services/api/vendors'
import {
  KEY_PERSONS,
  KEY_BORROWERS,
  KEY_INVESTORS,
  KEY_VENDORS,
} from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Person } from 'types'
import { Filter, Pagination, Sort } from 'types/table'

const usePersons = ({
  search,
  filter,
  pagination = { page: 0, size: 500 },
  sort,
}: {
  search?: string
  filter?: Filter
  pagination?: Pagination
  sort?: Sort
} = {}) => {
  const nextFilter = size(filter) ? filter : undefined
  return useQuery({
    queryKey: [KEY_PERSONS, search, nextFilter, pagination, sort],
    queryFn: () =>
      getPersons({
        search,
        filter: nextFilter,
        page: pagination,
        sort,
      }),
    placeholderData: keepPreviousData,
  })
}

const useUpdateOwners = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ person, owners }: { person: Person; owners: string[] }) => {
      const updateOwners = person.isBorrower
        ? updateBorrowerOwners
        : person.isInvestor
          ? updateInvestorOwners
          : updateVendorOwners
      return updateOwners({ id: person.id, owners })
    },
    onError: handleErrorResponse,
    onSuccess: (borrower: Person) => {
      queryClient.invalidateQueries({
        queryKey: [
          borrower.isBorrower
            ? KEY_BORROWERS
            : borrower.isInvestor
              ? KEY_INVESTORS
              : KEY_VENDORS,
          borrower?.id,
        ],
      })
    },
  })
}

export { usePersons, useUpdateOwners }
